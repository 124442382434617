import * as React from "react"

import { AppStateContext } from "../../utils/PageWrapper"
import styled from "styled-components"
import { _h2 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { _linkButton } from "../shared/buttons"
import { Row, Col } from "react-flexbox-grid"
import { prismicGetText } from "../../utils/prismicHelper"
import Slider from "react-slick"
import { LARGE_DESKTOP_SCREEN_WIDTH, getWindowDimensions } from "../../utils/responsiveHelper"
import { SliderSettings } from "../../components/shared/SliderSettings"
import { StringMap } from "../../utils"

const _productCard = styled.div`
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    text-align: center;
    padding: 1.5em;
`

const _productIcon = styled.img`
    width: 116px;
    height: 116px;
    object-fit: contain;
    margin-bottom: 0.7em;
`

const _productTitle = styled.figure`
    height: 48px;
    font-family: Poppins;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.5px;
    text-align: center;
    color: var(--dark);
    margin: 0;
    margin-bottom: 1em;
`

const _productSummary = styled.p`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: center;
    color: var(--slate-grey);
    margin: 0;
    margin-bottom 3em;
    min-height: 150px; 
`

const _productCardWrapper = styled.div`
    padding: 0.9em;
`

const _productCardButton = styled(_linkButton)`
    width: 90%;
`

interface ProductItemProps {
    icon: string
    summary: string
    title: string
    button: JSX.Element
}

interface ProductsProps {
    title: string
    lead: string
    products: [ProductItem]
}

interface ProductItem {
    product_icon: any
    product_name: any
    product_description: any
    cta_label: any
}

export const ProductItem: React.FC<ProductItemProps> = ({ icon, title, summary, button }) => {
    return (
        <_productCardWrapper>
            <_productCard>
                <_productIcon src={icon} />
                <_productTitle>{title}</_productTitle>

                <_productSummary>{summary}</_productSummary>

                {button}
            </_productCard>
        </_productCardWrapper>
    )
}

export const Products: React.FC<ProductsProps> = ({ title, lead, products }) => {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions())
    const appState = React.useContext(AppStateContext)

    const productLinks: StringMap = {
        Radar: "products/radar",
        Debrief: "products/debrief",
        Dialogues: "products/dialogue",
        Ranger: "products/ranger"
    }

    const slidesToShow = windowDimensions >= LARGE_DESKTOP_SCREEN_WIDTH ? 4 : 1

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    })

    const desktopContent = (
        <>
            <Row center="xs">
                <Col xl={6} lg={8} sm={12}>
                    <_h2>{title}</_h2>

                    <_Blockquote align={appState.isMobile ? "left" : "center"} fontSize={16} lineHeight={1.5}>
                        {lead}
                    </_Blockquote>
                </Col>
            </Row>

            <Slider {...SliderSettings({ slidesToShow })}>
                {products.map((product, index) => (
                    <ProductItem
                        key={index}
                        icon={product.product_icon.url}
                        title={prismicGetText(product.product_name)}
                        summary={prismicGetText(product.product_description)}
                        button={
                            <_productCardButton to={productLinks[prismicGetText(product.product_name)]}>
                                {prismicGetText(product.cta_label)}
                            </_productCardButton>
                        }
                    />
                ))}
            </Slider>
        </>
    )

    const mobileContent = (
        <>
            <Row center="xs">
                <Col lg={6} md={8} sm={12}>
                    <_h2>{title}</_h2>

                    <_Blockquote fontSize={16} lineHeight={1.5}>
                        {lead}
                    </_Blockquote>
                </Col>
            </Row>

            <Slider {...SliderSettings({ slidesToShow })}>
                {products.map((product, index) => (
                    <ProductItem
                        key={index}
                        icon={product.product_icon.url}
                        title={prismicGetText(product.product_name)}
                        summary={prismicGetText(product.product_description)}
                        button={
                            <_productCardButton to={productLinks[prismicGetText(product.product_name)]}>
                                {prismicGetText(product.cta_label)}
                            </_productCardButton>
                        }
                    />
                ))}
            </Slider>
        </>
    )

    if (appState.isMobile) {
        return mobileContent
    } else {
        return desktopContent
    }
}
