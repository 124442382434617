import * as React from "react"

import Slider from "react-slick"
import { Row, Col } from "react-flexbox-grid"

import { _h2 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { SliderSettings } from "../shared"
import { _customMarginRow } from "../shared/row.styles"
import { AppStateContext } from "../../utils/PageWrapper"
import {
    _img,
    _index,
    _wrapper,
    _nextSlideButton,
    _itemPosition,
    _indexPosition,
    _separator
} from "./intelligence.styles"
import NextSlideButton from "../../images/v2/intelligence/next-slide-icon.svg"

interface IntelligenceProps {
    title: string | JSX.Element
    leads: JSX.Element | string
    items: ItemProps[]
}

interface ItemProps {
    index: string
    text: string | string[]
    image: string
}

export const Intelligence: React.FC<IntelligenceProps> = ({ title, leads, items }) => {
    const appState = React.useContext(AppStateContext)
    const slider = React.useRef<Slider>(null)

    const scrollToNextSlide = () => {
        if (slider && slider.current) {
            slider.current.slickNext()
        }
    }

    const findNextIndex = ({ current, items }: { current: string; items: ItemProps[] }): string => {
        const currentInt = parseInt(current)
        if (currentInt + 1 > items.length) {
            return "01"
        }

        return `0${currentInt + 1}`
    }

    const desktopItem = (item: ItemProps) => (
        <>
            <_wrapper>
                <Col lg={5} md={6} sm={12} style={{ paddingTop: "8em" }}>
                    <_Blockquote maxWidth="270px" marginLeft="20px">
                        {item.text}
                        <_index>{item.index}</_index>
                    </_Blockquote>

                    <_itemPosition>
                        <_indexPosition className="current">{item.index}</_indexPosition>

                        <_separator />

                        <_indexPosition>{findNextIndex({ current: item.index, items })}</_indexPosition>
                    </_itemPosition>
                </Col>

                <Col lg={7} md={6} sm={12} style={{ position: "relative" }}>
                    <_img src={item.image} />
                    <_nextSlideButton onClick={scrollToNextSlide}>
                        <img src={NextSlideButton} />
                    </_nextSlideButton>
                </Col>
            </_wrapper>
        </>
    )

    const mobileItem = (item: ItemProps) => (
        <>
            <_wrapper>
                <Col sm={12}>
                    <_Blockquote maxWidth="270px" marginLeft="20px">
                        {item.text}
                        <_index>{item.index}</_index>
                    </_Blockquote>

                    <div style={{ position: "relative" }}>
                        <_img src={item.image} />
                        <_nextSlideButton onClick={scrollToNextSlide}>
                            <img src={NextSlideButton} />
                        </_nextSlideButton>
                    </div>

                    <_itemPosition>
                        <_indexPosition className="current">{item.index}</_indexPosition>

                        <_separator />

                        <_indexPosition>{findNextIndex({ current: item.index, items })}</_indexPosition>
                    </_itemPosition>
                </Col>
            </_wrapper>
        </>
    )

    if (!appState.isReady) return null

    return (
        <>
            <_customMarginRow>
                <Col xs={12}>
                    <Row>
                        <Col xl={6} lg={8} sm={12}>
                            <_h2 fontSizeSm={36} align="left">
                                {title}
                            </_h2>
                            <_Blockquote align="left">{leads}</_Blockquote>
                        </Col>
                    </Row>
                </Col>
            </_customMarginRow>

            <_customMarginRow center="xs" marginTop={appState.isMobile ? 0 : -8}>
                <Col sm={12}>
                    <Slider ref={slider} {...SliderSettings({ slidesToShow: 1, arrows: false, dots: false })}>
                        {items.map(item => {
                            return appState.isMobile ? mobileItem(item) : desktopItem(item)
                        })}
                    </Slider>
                </Col>
            </_customMarginRow>
        </>
    )
}
