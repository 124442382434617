import * as React from "react"

import scrollTo from "gatsby-plugin-smoothscroll"

import { _h1 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { _externalLinkButton } from "../shared/buttons/"
import { Row, Col } from "react-flexbox-grid"
import { AppStateContext } from "../../utils/PageWrapper"

import heroVideo from "../../images/v2/radiclewebsitegif.mp4"
import macbookContainerImg from "../../images/v2/macbookContainer.png"

import styled from "styled-components"

interface HeroProps {
    title: string | JSX.Element
    lead: string
    buttonLabel: string
}

const _laptopWrapper = styled.div`
    box-sizing: border-box;
    background: url(${macbookContainerImg}) center center no-repeat;
    background-size: contain;
    position: relative;
    padding-top: 25px;
    padding-bottom: 67.5%;
    height: 0;

    video {
        padding: 11.9% 15.5% 14.8%;
        padding: 10.9% 15.4% 14.8%;
        padding: 7.9% 15.4% 11.8%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

`

export const Hero: React.FC<HeroProps> = ({ title, lead, buttonLabel }) => {
    const appState = React.useContext(AppStateContext)
    return (
        <Row>
            <Col xl={5} lg={5} md={12} sm={12}>
                <_h1 fontSizeSm={42} maxWidth={"450px"}>
                    {title}
                </_h1>

                <_Blockquote>{lead}</_Blockquote>
                <_externalLinkButton
                    href="#newApproaches"
                    onClick={event => {
                        event.preventDefault()
                        scrollTo("#newApproaches")
                    }}>
                    {buttonLabel}
                </_externalLinkButton>
            </Col>

            {!appState.isMobile && (
                <Col xl={7} lg={7} md={12} sm={12}>
                    <_laptopWrapper>
                        <video
                                controls={false}
                                autoPlay
                                muted
                                loop
                            >
                            <source src={heroVideo} type="video/mp4" />
                        </video>

                    </_laptopWrapper>
                </Col>
            )}
        </Row>
    )
}
