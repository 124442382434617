import * as React from "react"

import { AppStateContext } from "../../utils/PageWrapper"
import { _h2 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { Row, Col } from "react-flexbox-grid"
import { ClientImageList } from "../shared"

import { _customMarginRow } from "../shared/row.styles"

interface ClientsProps {
    title: string
    clientLogos: ClientLogo[]
}

interface ClientLogo {
    client_logo: {
        alt?: string
        url: string
    }
}

export const Clients: React.FC<ClientsProps> = ({ title, clientLogos }) => {
    const appState = React.useContext(AppStateContext)

    return (
        <_customMarginRow marginTop={"0"}>
            <Col xs={12}>
                <Row center="xs">
                    <Col xl={6} lg={8} sm={12}>
                        <_Blockquote marginTop={"0"} marginBottom={"0"} align={appState.isMobile ? "left" : "center"}>
                            {title}
                        </_Blockquote>
                    </Col>
                </Row>

                <Row middle="xs" center="xs">
                    <ClientImageList clientLogos={clientLogos} />
                </Row>
            </Col>
        </_customMarginRow>
    )
}
