import * as React from "react"

import { AppStateContext } from "../../utils/PageWrapper"
import { _h2 } from "../shared/headline.styles"
import { Row, Col } from "react-flexbox-grid"
import styled from "styled-components"
import { _linkButton, _linkBlockButton } from "../shared/buttons/"
import { _Blockquote } from "../shared/blockquote.styles"
import CreatingValue from "../../images/creating-value.svg"
import { PersonSignature } from "../shared"
import { em } from "../../styles"

const _description = styled.figure`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--dark);
    margin: 0;
`

const _svgImage = styled.img`
    position: absolute;
    top: -9em;
    left: 35em;
    z-index: -1;
`

const _personWrapper = styled.div`
    margin-top: 1em;
    width: 300px;
`

interface ContactProps {
    header: string
    title: string
    lead: string
    buttonLabel: string
    testimonial: TestimonialProps
}

interface TestimonialProps {
    body: string
    person: TestimonialPersonProps
}

interface TestimonialPersonProps {
    name: string
    position: string
    avatar: string
}

export const Contact: React.FC<ContactProps> = ({ header, title, lead, buttonLabel, testimonial }) => {
    const appState = React.useContext(AppStateContext)

    const desktopContent = (
        <>
            <_svgImage src={CreatingValue} />
            <Row>
                <Col lg={5} md={12}>
                    <_description>{header}</_description>

                    <_h2 align="left" style={{ marginTop: em(0.2) }}>
                        {title}
                    </_h2>
                    <_Blockquote style={{ marginBottom: em(2) }}>{lead}</_Blockquote>

                    <_linkButton to="/contact">{buttonLabel}</_linkButton>

                    <_Blockquote fontSize={14} lineHeight={1.43} style={{ marginBottom: em(2), marginTop: em(3) }}>
                        {testimonial.body}
                    </_Blockquote>

                    <_personWrapper>
                        <PersonSignature
                            name={testimonial.person.name}
                            title={testimonial.person.position}
                            image={testimonial.person.avatar}
                            isMobile={appState.isMobile}
                        />
                    </_personWrapper>
                </Col>
            </Row>
        </>
    )

    const mobileContent = (
        <Row>
            <Col lg={5} md={12}>
                <_description>{header}</_description>

                <_h2 align="left" style={{ marginTop: em(0.2) }}>
                    {title}
                </_h2>

                <img src={CreatingValue} style={{ margin: "auto", width: "100%" }} />

                <_Blockquote style={{ marginBottom: em(2) }}>{lead}</_Blockquote>
                <_linkBlockButton to="/contact">{buttonLabel}</_linkBlockButton>
            </Col>
        </Row>
    )

    return appState.isMobile ? mobileContent : desktopContent
}
