import styled from "styled-components"

export const _img = styled.img`
    max-width: 100%;

    @media only screen and (min-width: 992px) {
        margin-top: -9em;
    }
`

export const _index = styled.span`
    font-family: Poppins;
    font-size: 56px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: rgba(255, 129, 85, 0.45);
    position: absolute;
    left: 0;
    top: 0;
    margin: -40px -10px;

    @media only screen and (min-width: 992px) {
        margin: -40px -25px;
    }
`

export const _wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0;
`

export const _nextSlideButton = styled.button`
    border: none;
    background: none;
    outline: none;
    position: absolute;
    cursor: pointer;

    right: 15px;
    left: unset;
    bottom: 0px;

    @media only screen and (min-width: 992px) {
        left: 10px;
        bottom: 20%;
        right: unset;
    }
`

export const _itemPosition = styled.div`
    text-align: left;

    @media only screen and (min-width: 992px) {
        margin-top: 3em;
    }
`

export const _indexPosition = styled.span`
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    text-align: center;
    color: #5a5a5a;

    &.current {
        color: #ff8155;
    }
`

export const _separator = styled.span`
    margin: 5px 9px;
    height: 1px;
    background-color: #646c7a;
    width: 60px;
    display: inline-block;
`
