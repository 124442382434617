import * as React from "react"

import { AppStateContext } from "../../utils/PageWrapper"
import { _h3 } from "../shared/headline.styles"
import { Row, Col } from "react-flexbox-grid"
import processDiagram from "../../images/funnel.svg"
import styled from "styled-components"
import { _Blockquote } from "../shared/blockquote.styles"
import { _processLink } from "../shared/links.styles"
import { prismicGetText } from "../../utils/prismicHelper"
import mobileProcessImg from "../../images/index/processing/group-6.png"
import mobileProcessImg2x from "../../images/index/processing/group-6@2x.png"
import mobileProcessImg3x from "../../images/index/processing/group-6@3x.png"
import { DESKTOP_SCREEN_WIDTH } from "../../utils/responsiveHelper"
import { StringMap } from "../../utils"

const _processDiagram = styled.img``

const _processWrapper = styled.div`
    margin: auto;
    margin-top: 8em;
    transform: translate(19px, 32px);

    @media only screen and (max-width: ${DESKTOP_SCREEN_WIDTH}px) {
        transform: translate(-25px, 25px);
        margin-top: 21em;
    }
`

const _processItemWrapper = styled.div`
    position: absolute;
    width: 200px;
    text-align: left;
    transform: translate(25%, 0px);
`

interface ProcessProps {
    title: string
    processItems: ItemProps[]
}

interface ProcessItemProps {
    positions: any
    title: string
    description?: string
    link?: JSX.Element
    align?: string
}

interface ItemProps {
    cta_label_process_item: any
    description_process_item: any
    icon_process_item: Object
    title_process_item: any
}

export const ProcessItem: React.FC<ProcessItemProps> = ({ positions, title, description, link, align }) => {
    return (
        <_processItemWrapper style={{ ...positions }}>
            <_h3
                fontSize={24}
                lineHeight={1.33}
                align={align ? align : "left"}
                style={{ marginTop: 0 + "px", marginBottom: 5 + "px" }}>
                {title}
            </_h3>

            <_Blockquote fontSize={16} lineHeight={1.5} style={{ marginTop: 0 + "px" }}>
                {description}
            </_Blockquote>

            {link}
        </_processItemWrapper>
    )
}

interface PositionProps {
    top: number
    right?: number
    left?: number
}

export interface StringPositionsMap {
    [key: string]: PositionProps
}

const getPositions = (title: string): PositionProps => {
    const shift = 235
    const processItemStart = 175

    const topPositions: StringPositionsMap = {
        Radars: {
            top: processItemStart,
            right: 0
        },

        Debriefs: {
            top: processItemStart + 1 * shift,
            right: 0
        },

        Dialogues: {
            top: processItemStart + 2 * shift,
            right: 0
        },

        "Diligence briefs": {
            top: processItemStart + 3 * shift,
            right: 0
        },

        Ranger: {
            top: processItemStart,
            left: 0
        }
    }

    return topPositions[title]
}

const productLinks: StringMap = {
    Radars: "products/radar",
    Debriefs: "products/debrief",
    Dialogues: "products/dialogue",
    "Diligence briefs": "products/debrief",
    Ranger: "products/ranger"
}

export const Process: React.FC<ProcessProps> = ({ title, processItems }) => {
    const appState = React.useContext(AppStateContext)

    const desktopContent = (
        <Row>
            <Col xs={12}>
                <Row>
                    <Col xs={12}>
                        <_h3>{title}</_h3>
                    </Col>
                </Row>

                <Row>
                    <_processWrapper>
                        <ProcessItem
                            align="center"
                            positions={{
                                left: 50 + "%",
                                transform: "translate(-55%, 0)",
                                top: 0
                            }}
                            title="Thesis"
                        />

                        <ProcessItem
                            align="center"
                            positions={{
                                left: 50 + "%",
                                transform: "translate(-55%, 0)",
                                bottom: 100
                            }}
                            title="Action"
                        />

                        <_processDiagram src={processDiagram} />
                        {processItems.map((item, index) => (
                            <ProcessItem
                                key={index}
                                positions={getPositions(prismicGetText(item.title_process_item))}
                                title={prismicGetText(item.title_process_item)}
                                description={prismicGetText(item.description_process_item)}
                                link={
                                    <_processLink to={productLinks[prismicGetText(item.title_process_item)]}>
                                        {prismicGetText(item.cta_label_process_item)}
                                    </_processLink>
                                }
                            />
                        ))}
                    </_processWrapper>
                </Row>
            </Col>
        </Row>
    )

    const mobileContent = (
        <Row>
            <Col xs={12}>
                <Row>
                    <Col xs={12}>
                        <_h3>{title}</_h3>
                    </Col>
                </Row>

                <Row>
                    <img
                        style={{ margin: "auto" }}
                        src={mobileProcessImg}
                        srcSet={`${mobileProcessImg2x} 2x, ${mobileProcessImg3x} 3x`}
                    />
                </Row>
            </Col>
        </Row>
    )

    return appState.isMobile ? mobileContent : desktopContent
}
