import styled from "styled-components"

export const _reponseContainer = styled.div`
    padding: 15px;
    border-radius: 22px;
    box-shadow: 0 2px 7px -1px rgba(135, 134, 234, 20%);
    background-color: rgba(255, 255, 255, 0.66);
    margin-bottom: 30px;
    margin-left: auto;
    display: flex;
    width: 100%;
    position: relative;
    overflow: hidden;

    @media only screen and (min-width: 992px) {
        padding: 25px;

        &.index-0 {
            width: 510px;
        }

        &.index-1 {
            width: 630px;
        }

        &.index-2 {
            width: 564px;
        }
    }
`

export const _containerBlur = styled.img`
    position: absolute;
    top: 0;
    height: 100%;
    opacity: 0.2;
`

export const _score = styled.div`
    height: 65px;
    width: 65px;
    display: block;
    flex-shrink: 0;
    margin-right: 10px;

    @media only screen and (min-width: 992px) {
        margin-right: 30px;
        height: 75px;
        width: 75px;
    }
`

export const _scoreText = styled.div`
    font-family: Poppins;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5a1e5f;

    font-size: 25px;

    span {
        color: inherit;
        font-size: 15px;
    }
`

export const _linkContainer = styled.div`
    text-align: center;
    margin: auto;
    display: block;
`

export const _textContainer = styled.div`
    display: block;
`
