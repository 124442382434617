import * as React from "react"

import { _h2, _h3 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { Row, Col } from "react-flexbox-grid"
import { _customMarginRow } from "../shared/row.styles"
import { _uppercaseLink } from "../shared/links.styles"
import { _cardDescription } from "../../components/shared/cards/card.styles"
import { _linkButton } from "../../components/shared/buttons"

import { _cardWrapper, _cardImage, _cardTitle, _cardContainer, _centered } from "./newNeeds.styles"

interface NewNeedsProps {
    title: string | JSX.Element
    leads: JSX.Element | string
    items: ItemProps[]
    ctaLabel: string
}

interface ItemProps {
    image: string
    title: string
    lead: string
}

export const NewNeeds: React.FC<NewNeedsProps> = ({ title, leads, items, ctaLabel }) => {
    return (
        <>
            <_customMarginRow>
                <Col xs={12}>
                    <Row center="xs">
                        <Col xl={6} lg={8} sm={12}>
                            <_h2 fontSizeSm={36}>{title}</_h2>
                            <_Blockquote align="center" maxWidth="380px" marginLeft="auto" marginRight="auto">
                                {leads}
                            </_Blockquote>
                        </Col>
                    </Row>
                </Col>
            </_customMarginRow>
            <_cardContainer>
                {items.map((item, index) => {
                    return (
                        <_cardWrapper key={index}>
                            <_cardImage src={item.image} />
                            <_cardTitle>{item.title}</_cardTitle>
                            <_cardDescription>{item.lead}</_cardDescription>
                        </_cardWrapper>
                    )
                })}
            </_cardContainer>
            <_centered>
                <_linkButton to="/products/debrief">{ctaLabel}</_linkButton>
            </_centered>
        </>
    )
}
