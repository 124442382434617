import * as React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import { Layout, _Section, HeadComponent } from "../components/shared"
import { Hero, Clients, TimeScale, Intelligence, NpsSurvey, NewNeeds } from "../components/indexPage"
import Hexagon1 from "../images/v2/hexagon-1.svg"
import { prismicGetText, prismicGetRichText, extractPrismicNode, prismicGetRichHeader } from "../utils/prismicHelper"
import { PageWrapper } from "../utils/PageWrapper"
import { getWindowDimensions, checkIfMobile } from "../utils"
import { TestimonialSection } from "../components/productsPage/Dialogue"

import NewNeedsImage1 from "../images/v2/newNeeds/insight.svg"
import NewNeedsImage2 from "../images/v2/newNeeds/connection.svg"
import NewNeedsImage3 from "../images/v2/newNeeds/talent.svg"

import NpsBlueBlur from "../images/v2/blue.svg"
import NpsOrangeBlur from "../images/v2/orange.svg"

const _svgImage = styled.img`
    position: absolute;
    top: 70px;
    left: 67%;
    z-index: -1;
`

export const prismicQuery = graphql`
    {
        prismic {
            ...indexFragment
            ...colorfulLogoFragment
        }
    }
`

interface IndexPageProps {
    data: {
        prismic: {
            allHomes: {
                edges: any
            }
        }
    }
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allHomes")
    if (!doc) return null

    const docClientLogos = extractPrismicNode(data, "allColorful_client_logos")
    if (!docClientLogos) return null

    const [isMobile, setIsMobile] = React.useState<boolean>(checkIfMobile(getWindowDimensions()))
    const [ready, setReady] = React.useState(false)

    React.useEffect(() => {
        if (typeof window !== `undefined`) {
            setReady(true)
        }

        function handleResize() {
            setIsMobile(checkIfMobile(getWindowDimensions()))
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const normalizedIntelligenceItems = doc.intelligence_items.map((item: any, index: number) => ({
        image: item.item_image.url,
        text: prismicGetText(item.item_description),
        index: `0${index + 1}`
    }))

    const getNpsImage = (i: number): string => {
        if (i === 1) return NpsBlueBlur

        return NpsOrangeBlur
    }

    const normalizedNpsItems = doc.nps_items.map((item: any, index: number) => ({
        text: prismicGetText(item.item_description),
        score: item.score_nps,
        blur: getNpsImage(index % 2)
    }))

    const normalizedTimeScaleItems = doc.clients_items_v2.map((item: any) => ({
        title: prismicGetText(item.item_title_clients_v2),
        text: prismicGetRichText(item.item_description_v2),
        image: item.item_image_v2.url
    }))

    const testimonialTitleSplitted = prismicGetText(doc.title_testimonials_v2).split(" ")

    return (
        <>
            <HeadComponent
                title="Radicle"
                keywords={prismicGetText(doc.seo_keywords_home)}
                description={prismicGetText(doc.seo_description_home)}
            />
            <PageWrapper>
                <Layout>
                    {!isMobile && ready && <_svgImage src={Hexagon1} />}

                    <_Section marginTopSm={3} marginBottom={"0"}>
                        <Hero
                            title={prismicGetRichHeader(doc.page_title_v2)}
                            lead={prismicGetText(doc.page_lead)}
                            buttonLabel={prismicGetText(doc.main_cta_label)}
                        />
                    </_Section>

                    <_Section marginTop={"0"}>
                        <Clients
                            title={prismicGetText(doc.client_logos_lead)}
                            clientLogos={docClientLogos.body[0].fields}
                        />
                    </_Section>

                    <_Section>
                        <TimeScale
                            title={prismicGetRichHeader(doc.title_clients_v2)}
                            leads={prismicGetText(doc.lead_clients)}
                            items={normalizedTimeScaleItems}
                        />
                    </_Section>

                    <_Section>
                        <Intelligence
                            title={prismicGetRichHeader(doc.title_intelligence)}
                            leads={prismicGetText(doc.description_intelligence)}
                            items={normalizedIntelligenceItems}
                        />
                    </_Section>

                    {ready && (
                        <_Section>
                            <TestimonialSection
                                title={
                                    <>
                                        {testimonialTitleSplitted[0]}&nbsp;
                                        <s>{testimonialTitleSplitted[1]}</s>
                                    </>
                                }
                                lead={
                                    prismicGetRichText(doc.description_testimonials_v2)
                                }
                                normalizedTestimonials={doc.body[0].fields}
                                displayBottomImage={false}
                            />
                        </_Section>
                    )}

                    <_Section>
                        <NpsSurvey title={prismicGetRichHeader(doc.title_nps)} items={normalizedNpsItems} />
                    </_Section>

                    <_Section>
                        <div id="newApproaches" style={{ position: "absolute", width: "100%", top: "-100px" }} />
                        <NewNeeds
                            title={prismicGetRichHeader(doc.title_approach_v2)}
                            leads={prismicGetText(doc.lead_approach)}
                            items={[
                                {
                                    title: prismicGetText(doc.icon_title_approach_1),
                                    lead: prismicGetText(doc.icon_description_approach_1),
                                    image: NewNeedsImage1
                                },
                                {
                                    title: prismicGetText(doc.icon_title_approach_2),
                                    lead: prismicGetText(doc.icon_description_approach_2),
                                    image: NewNeedsImage2
                                },
                                {
                                    title: prismicGetText(doc.icon_title_approach_3),
                                    lead: prismicGetText(doc.icon_description_approach_3),
                                    image: NewNeedsImage3
                                }
                            ]}
                            ctaLabel={prismicGetText(doc.cta_label_products)}
                        />
                    </_Section>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default IndexPage
