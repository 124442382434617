import * as React from "react"

import { _h2 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { Row, Col } from "react-flexbox-grid"

import { _customMarginRow } from "../shared/row.styles"
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import "./progressbar-styles.css"
import { _uppercaseLink } from "../shared/links.styles"
import { AppStateContext } from "../../utils/PageWrapper"
import {
    _reponseContainer,
    _score,
    _scoreText,
    _linkContainer,
    _textContainer,
    _containerBlur
} from "./npsSurvey.styles"

interface NpsSurveyProps {
    title: string | JSX.Element
    items: ItemProps[]
}

interface ItemProps {
    text: string | string[]
    score: number
    blur: string
}

export const NpsSurvey: React.FC<NpsSurveyProps> = ({ title, items }) => {
    const appState = React.useContext(AppStateContext)
    return (
        <>
            <_customMarginRow>
                <Col xs={12}>
                    <Row>
                        <Col xl={5} lg={5} md={12}>
                            <_h2 align="left" marginTop={appState.isMobile ? 1 : 3} fontSize={42}>
                                {title}
                            </_h2>
                        </Col>

                        <Col xl={7} lg={7} md={12}>
                            {items.map((item, index: number) => (
                                <_reponseContainer key={index} className={`index-${index}`}>
                                    <_containerBlur src={item.blur} />
                                    <_score>
                                        <CircularProgressbarWithChildren value={item.score * 10}>
                                            <_scoreText>
                                                {item.score}
                                                <span>/10</span>
                                            </_scoreText>
                                        </CircularProgressbarWithChildren>
                                    </_score>
                                    <_textContainer>
                                        <_Blockquote
                                            color="var(--black)"
                                            align="left"
                                            marginBottom={"0"}
                                            marginTop={"0"}>
                                            {item.text}
                                        </_Blockquote>
                                    </_textContainer>
                                </_reponseContainer>
                            ))}
                            {/* <_linkContainer>
                                <_uppercaseLink>See more</_uppercaseLink>
                            </_linkContainer> */}
                        </Col>
                    </Row>
                </Col>
            </_customMarginRow>
        </>
    )
}
