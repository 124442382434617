import * as React from "react"

import { AppStateContext } from "../../utils/PageWrapper"
import { _h2 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { Row, Col } from "react-flexbox-grid"
import { _customMarginRow } from "../shared/row.styles"
import { GenericItem } from "../shared"
import styled from "styled-components"

import ApproachSVG from "../../images/approach/wave2.svg"

interface FlexibleApproachProps {
    title: string
    lead: string
    resources: ResourceItemProps[]
}

interface ResourceItemProps {
    title: string
    description: string
    image: string
}

const _svgImage = styled.img`
    position: absolute;
    top: -4em;
    left: 30%;
    z-index: -1;
`

export const FlexibleApproach: React.FC<FlexibleApproachProps> = ({ title, lead, resources }) => {
    const appState = React.useContext(AppStateContext)

    return (
        <>
            {!appState.isMobile && <_svgImage src={ApproachSVG} />}
            <Row center="xs">
                <Col lg={8} md={8} sm={12}>
                    <_h2>{title}</_h2>
                    <_Blockquote align="center">{lead}</_Blockquote>
                </Col>
            </Row>

            <_customMarginRow center="xs">
                {resources.map((resource: ResourceItemProps, index) => (
                    <Col lg={4} md={12} sm={12} key={index}>
                        <GenericItem
                            titleClassName="mainPage"
                            cardImageClassName="mainPage"
                            leadAlign="center"
                            title={resource.title}
                            lead={resource.description}
                            image={resource.image}
                        />
                    </Col>
                ))}
            </_customMarginRow>
        </>
    )
}
