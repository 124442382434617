import * as React from "react"

import { AppStateContext } from "../../utils/PageWrapper"
import { _h2 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { Row, Col } from "react-flexbox-grid"
import { ShadowedBox, _shadowedBoxContainer } from "../shared"

import { _customMarginRow } from "../shared/row.styles"

interface TimeScaleProps {
    title: string | JSX.Element
    leads: JSX.Element | string
    items: ItemProps[]
}

interface ItemProps {
    title: string
    text: string | string[]
    image: string
}

export const TimeScale: React.FC<TimeScaleProps> = ({ title, leads, items }) => {
    const appState = React.useContext(AppStateContext)

    return (
        <>
            <_customMarginRow>
                <Col xs={12}>
                    <Row center="xs">
                        <Col xl={6} lg={8} sm={12}>
                            <_h2 fontSizeSm={36} marginTop={1}>
                                {title}
                            </_h2>
                            <_Blockquote align={appState.isMobile ? "left" : "center"}> {leads} </_Blockquote>
                        </Col>
                    </Row>
                </Col>
            </_customMarginRow>
            <_shadowedBoxContainer>
                {items.map((item, index: number) => {
                    return (
                        <ShadowedBox
                            title={item.title}
                            description={item.text}
                            image={item.image}
                            itemMaxWidth="430px"
                            key={index}
                        />
                    )
                })}
            </_shadowedBoxContainer>
        </>
    )
}
