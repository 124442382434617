import * as React from "react"

import { AppStateContext } from "../../utils/PageWrapper"
import { _h2 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { Row, Col } from "react-flexbox-grid"
import Change from "../../images/change.svg"
import styled from "styled-components"

const _svgImage = styled.img`
    position: absolute;
    top: -13em;
    left: -20%;
    z-index: -1;
`

interface ManifestProps {
    title: string
    lead: string
}

export const Manifest: React.FC<ManifestProps> = ({ title, lead }) => {
    const appState = React.useContext(AppStateContext)

    return (
        <>
            {!appState.isMobile && <_svgImage src={Change} />}
            <Row center="xs">
                <Col xl={6} lg={8} sm={12}>
                    <_h2>{title}</_h2>
                    <_Blockquote align={appState.isMobile ? "left" : "center"}>{lead}</_Blockquote>
                </Col>
            </Row>
        </>
    )
}
